html {
    * {
        transition: all 0.2s;
    }

    @keyframes gradient {
        0% {
            background-position: 0 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0 50%;
        }
    }

    height: 100%;
    width: 100%;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)
        no-repeat;
    background-size: 400% 800%;
    animation: gradient 15s ease infinite;

    body {
        position: relative;
        z-index: 0;
        font-family: "ALS Schlange Sans", sans-serif;
        line-height: 16px;
        font-weight: normal;
        color: #313131;
        text-decoration: none;
        padding: 0;
        margin: 0;

        a {
            text-decoration: none;
        }

        .content {
            min-height: 100vh;

            .side {
                font-size: 15px;
                width: 14%;
                position: fixed;
                padding: 0;
                margin: 25px 0 0 2%;

                a {
                    color: #efefef;
                    &:hover {
                        color: #b9deed;
                    }
                }
                &.left {
                    left: 0;
                }
                &.right {
                    right: 0;
                }
            }

            .main {
                font-size: 16px;
                padding: 10px 20px 10px 20px;
                background: linear-gradient(90deg, #d3ebf5, #efefef, #d3ebf5)
                    no-repeat;
                background-size: cover;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;

                .search {
                    margin: 10px 0;
                    display: flex;
                    flex-direction: row;
                    align-content: center;
                    align-items: center;

                    p {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    input {
                        border: 2px solid #90d3ee;
                        border-radius: 4px;
                    }

                    form {
                        font-size: large;

                        label {
                            display: inline-block;
                            text-align: left;
                            width: 150px;
                            padding-right: 1rem;
                        }
                    }

                    #fio_form input {
                        width: 135px;
                        padding: 1px 2px;
                    }

                    .ege {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        form input {
                            width: 40px;
                        }
                    }

                    & > div {
                        padding: 1em;
                        border: 1px solid #9e9e9e;
                        border-radius: 1em;
                        margin: 0 1rem;
                    }

                    button {
                        margin-bottom: 3px;
                    }
                }

                #results {
                    display: table;
                    width: 100%;

                    table {
                        margin: 10px 0;
                        table-layout: auto;
                        width: inherit;
                        border-collapse: collapse;

                        caption {
                            padding-bottom: 15px;
                            font-weight: bold;
                            font-size: 20px;
                        }

                        tr {
                            height: 52px;
                        }

                        th {
                            border: 1px solid black;
                            padding: 0 5px;

                            &#stream {
                                width: 85px;
                            }
                            &:not(#stream) {
                                &:hover {
                                    cursor: pointer;
                                    background: #bae0f5;
                                }
                            }
                            &.sorted {
                                &[data-order="-1"] {
                                    color: #0092bd;
                                }
                                &[data-order="1"] {
                                    color: #2967c5;
                                }
                            }
                        }

                        tbody {
                            tr {
                                &:hover {
                                    background-color: #bae0f5 !important;
                                }

                                td {
                                    border: 1px solid black;
                                    padding: 0 5px;

                                    a {
                                        white-space: nowrap;
                                        color: #030d10;

                                        &:visited {
                                            color: #8a00ad;
                                        }
                                        &:hover {
                                            color: #0045ad;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media (orientation: landscape) {
                .main {
                    width: auto;
                    position: relative;
                    margin: 0 16%;

                    .search form {
                        width: 320px;
                    }
                }
            }

            @media (max-width: 1605px) {
                .main .search form {
                    width: auto;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }

            @media (max-width: 1350px) {
                .main {
                    width: 60%;
                    margin: auto;

                    .search {
                        flex-direction: column;

                        div {
                            margin: 2px 1rem;
                        }
                    }
                }
            }

            @media (max-width: 1080px) {
                .side {
                    display: none;
                }

                .main {
                    width: auto;
                    position: relative;
                    top: 0;
                    left: 0;
                    margin: 0;

                    .search {
                        flex-direction: row;
                    }
                }
            }

            @media (max-width: 930px), (orientation: portrait) {
                .main {
                    .search {
                        flex-direction: column;
                    }
                    table {
                        overflow-y: scroll;
                    }
                }
            }
        }

        .copyright {
            height: 40px;
            margin-top: -40px;
            width: 100%;
            font-size: 12px;
            text-align: center;
            margin-bottom: 5px;

            a {
                color: #efefef;
                opacity: 0.65;
            }
        }
    }
}
